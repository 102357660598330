/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Box, Flex, IconButton } from '@theme-ui/components'
import { IconArrowLeft } from "../icons/arrowLeft";
import { IconArrowRight } from "../icons/arrowRight";


export const TableFooter: FC = ({ }) => {
  return <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end', p: 3 }}>
    <Flex sx={{ alignItems: 'center' }}>
      <Box pr={4}>
        Rows per page 10
      </Box>
      <Box pr={3}>
        1 - 4 of 4
      </Box>
      <Box pl={2} pr={1}>
        <IconButton>
          <IconArrowLeft />
        </IconButton>
      </Box>
      <Box pr={0} pl={1}>
        <IconButton>
          <IconArrowRight />
        </IconButton>
      </Box>
    </Flex>
  </Flex>
}


