/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Grid } from '@theme-ui/components'

export const TableRow: FC<any> = ({ columns, id, selected, children }) => {
  return (
    <Grid key={id} sx={{
      borderBottom: '1px solid',
      borderColor: 'muted',
      p: 3,
      backgroundColor: selected ? 'muted' : 'background',
      gridTemplateColumns: columns,
      '&:hover': {
        bg: 'muted'
      }
    }}>
      {children}
    </Grid>
  )
}

