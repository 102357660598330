/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Box } from '@theme-ui/components'

export const CellHeader: FC = ({ children }) => {
  return (
    <Box sx={{
      fontWeight: 'bold'
    }} >
      {children}
    </Box>
  )
}