import { useState, } from "react";

export const useSelection = (options: any[]) => {
  const [selection, ss] = useState<{ [key: string]: boolean }>({})

  const select = (id: string) => {
    ss({ ...selection, [id]: !selection[id] })
  }

  const all = (!Object.values(selection).some(s => !s) && Object.keys(selection).length === options.length && Object.keys(selection).length > 0)
  const onEnableAll = () => {
    const checks = options.reduce((acc, n) => {
      if (all) {
        acc[n.uid] = false
      } else {
        acc[n.uid] = true
      }
      return acc
    }, {} as { [key: string]: boolean });
    ss(checks)
  }

  return {
    selection,
    select,
    all,
    onEnableAll
  }
}