/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Heading, Message, Grid, Container } from '@theme-ui/components'
import { app } from "firebase";
import { useFirebase } from 'gatsby-plugin-firebase'

export const Dashboard: FC<RouteComponentProps> = () => {
  const [counters, scs] = useState<any>({})

  useFirebase((firebase: app.App) => {
    firebase.firestore()
      .collection("counters")
      .onSnapshot((data) => {
        const counters = data.docs
          .map(doc => {
            return {
              id: doc.id,
              data: doc.data()
            }
          })
          .reduce((acc, next) => {
            acc[next.id] = next.data.count
            return acc
          }, {} as any)

        scs(counters)
      })
  }, [])

  return (
    <Container>
      <Box py={4}>
        <Grid columns={[2, 3, 4]}>
          <Message>
            {counters.users} Users
          </Message>
          <Message>
            {counters.wallpapers} Wallpapers
          </Message>
        </Grid>
      </Box>
    </Container>
  )
}