/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Flex } from '@theme-ui/components'
import { TableContext } from "./tableContext";

export interface TableProps {
  content: any[]
  selection: { [key: string]: boolean }
  select: (id: string) => void
  selectAll: any
}

export const Table: FC<TableProps> = ({ children, content, selection, select, selectAll }) => {

  return <Flex sx={{
    height: ['100%', 'auto'],
    flexDirection: 'column',
    mt: [0, 3],
    backgroundColor: 'background',
    borderRadius: 4,
    boxShadow: '1px 1px 30px -4px rgba(0,0,0,0.1)'
  }}>
    <TableContext.Provider value={{
      content,
      selection,
      select,
      selectAll
    }}>
      {children}
    </TableContext.Provider>
  </Flex>
}