/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FullModal } from "../components/organisms/fullModal";
import { Box, Heading, Text, Container, Grid, Label, Input, Button, Flex, Textarea } from '@theme-ui/components'

import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { FC, useContext, useRef, useState } from "react";
import { app } from "firebase";

export const WallpaperEditor: FC<any> = ({ open, close, wid }) => {
  const optimizeCallable = useRef<any>();
  const analyseCallable = useRef<any>();
  const downloadCallable = useRef<any>();
  const [wallpaper, sw] = useState<firebase.firestore.DocumentSnapshot | undefined>(undefined)
  const fb = useContext(FirebaseContext) as app.App
  const [model, sm] = useState({
    name: "",
    category: "",
    tags: [],
    description: "",
  })

  const onSave = () => {
    if (!fb) {
      return
    }
    fb
      .firestore()
      .collection("wallpapers")
      .doc(wid)
      .update(model)
      .then(console.log)
  }

  const onDelete = () => {
    if (!fb || !wallpaper) {
      return
    }

    wallpaper.ref.delete().then(close)
  }

  useFirebase((firebase: app.App) => {
    optimizeCallable.current = firebase.functions().httpsCallable("callOptimizeImages")
    analyseCallable.current = firebase.functions().httpsCallable("callAnalyseImage")
    downloadCallable.current = firebase.functions().httpsCallable("callDownloadOriginal")
  }, [])

  useFirebase((firebase: app.App) => {
    if (!wid) {
      return
    }
    firebase.firestore().collection("wallpapers").doc(wid).onSnapshot((doc) => {
      sw(doc as firebase.firestore.DocumentSnapshot)
      const data = doc.data()!
      sm({
        name: data.name || "",
        category: data.category || "",
        tags: data.tags || [],
        description: data.description || "",
      })
    })
  }, [wid])


  if (!wallpaper) {
    return null
  }

  const data = wallpaper.data()
  if (!data) {
    return null
  }

  return (
    <FullModal open={open} close={close}>
      <div onClick={close} sx={{
        position: 'absolute',
        top: 16,
        right: 16,
        cursor: 'pointer'
      }}>X</div>
      <Flex>
        <img src={data.images?.full?.url || ""} sx={{ maxWidth: '200px', flex: '0 200px' }} />
        <Flex sx={{ flex: 1, mx: 2, alignItems: 'flex-end', }}>
          <Box>
            <Box mt={2}>
              <Button onClick={() => {
                downloadCallable.current?.call(null, { wid })
                  .then(console.log).catch(console.log)
              }}>Download Original</Button>
            </Box>
            <Box mt={2}>
              <Button onClick={() => {
                optimizeCallable.current?.call(null, { wallId: wid, filePath: `/wallpapers/${wid}/original.jpg` })
                  .then(console.log).catch(console.log)
              }}>Optimize</Button>
            </Box>
            <Box mt={2}>
              <Button onClick={() => analyseCallable.current?.call(null, { wallId: wid })
                .then(console.log).catch(console.log)}>Analyse</Button>
            </Box>
          </Box>
        </Flex>
      </Flex>

      <Box mb={3} mt={3}>
        <Label>Title</Label>
        <Input value={model.name} onChange={(e: any) => { sm({ ...model, name: e.target.value }) }} />
      </Box>
      <Box mb={3}>
        <Label>Category</Label>
        <Input value={model.category} onChange={(e: any) => { sm({ ...model, category: e.target.value }) }} />
      </Box>
      <Box mb={3}>
        <Label>Description</Label>
        <Textarea onChange={(e: any) => { sm({ ...model, description: e.target.value }) }} value={model.description}></Textarea>
      </Box>
      <Box mb={3}>
        <Label>Suggested Tags</Label>
        <Grid gap={2} columns={[3, 4]} as="ul" sx={{ margin: 0, padding: 0, mt: 1 }}>
          {data?.suggestedLabels?.map((l: any) => {
            return <li key={l.id} sx={{
              listStyle: 'none',
              p: 2,
              background: 'highlight',
              borderRadius: 4,
              m: 0
            }}>{l.name}</li>
          })}
        </Grid>
      </Box>
      <Box mb={3}>
        <Label>Tags</Label>
        <Input value={model.tags.join(", ")} onChange={(e: any) => { sm({ ...model, tags: e.target.value.split(",").map((r: string) => r.trim()) }) }} />
      </Box>
      <Box mb={3}>
        <Label>Images</Label>
        <ul>
          {Object.keys(data.images || {}).map(key => {
            return <li key={key} sx={{ p: 0, m: 0 }}>{key}</li>
          })}
        </ul>
      </Box>
      <Box mb={3}>
        <Label>Wallpaper of the day</Label>
      </Box>
      <Box mb={3}>
        <Label>Colors</Label>
        <Grid columns={[3]} sx={{ height: 48, width: '100%' }}>
          {(data.colors || []).map((c: any, i: number) => {
            return <Box key={i} sx={{
              height: 48,
              borderRadius: 4,
              width: '100%',
              backgroundColor: `rgb(${c.color.red}, ${c.color.green}, ${c.color.blue})`
            }} />
          })}
        </Grid>
      </Box>
      <Box mb={3}>
        <Button onClick={onSave}>Save</Button>
      </Box>
      <Box mb={3}>
        <Button variant="danger" onClick={onDelete}>Delete</Button>
      </Box>
    </FullModal>
  )
}