import React from 'react';

export const IconArrowRight = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M10 17l5-5-5-5v10z" fill="currentColor" />
      <path fill="none" d="M0 24V0h24v24H0z" />

    </svg>
  )
}