/** @jsx jsx */
import { jsx, } from "theme-ui";

import { FC, useState, useEffect, useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Heading, Text, Container, Grid } from '@theme-ui/components'
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { Wallpaper } from "../model/wallpaper";

import { CardImage, CardTitle, Card } from "../components/organisms/card";
import { WallpaperEditor } from "./wallpaperEditor";

export const Wallpapers: FC<RouteComponentProps> = () => {
  const [wps, setWPS] = useState<Wallpaper[]>([])
  const [a, setA] = useState<any>(undefined)

  useFirebase((fb: any) => {
    fb
      .firestore()
      .collection("wallpapers")
      .orderBy("createdAt", "desc")
      .limit(100)
      .onSnapshot((snapshot: any) => {
        const wps: any[] = []

        snapshot.forEach((doc: any) =>
          wps.push({ ...doc.data(), uid: doc.id }),
        );

        setWPS(wps)
      })
  }, []);

  return (
    <Container>
      <Grid columns={[2, 3, 4]} my={3}>
        {wps.map((wp) => {
          return (
            <Box key={wp.uid} sx={{ my: 2, position: 'relative' }} onClick={() => setA(wp)}>
              <Card>
                <CardImage url={wp.images?.full?.url || ""} />
                <CardTitle>{wp.name}</CardTitle>
              </Card>
            </Box>
          )
        })}
      </Grid>
      <WallpaperEditor open={a} close={() => setA(undefined)} wallpaper={a} wid={a?.uid} />
    </Container>
  )
}