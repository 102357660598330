/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Box, Flex, IconButton, Button, Badge } from '@theme-ui/components'
import { IconFilterList } from "../icons/filterList";



export const TableHeader: FC<any> = ({ selection }) => {
  return (
    <Flex p={3}>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center', mr: 2 }}><IconButton><IconFilterList /></IconButton></Flex>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}><Badge variant="primary">Sort: createdAt - desc</Badge></Flex>
      <Flex sx={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
        {Object.values(selection).some(s => s) && <Button>Delete</Button>}
      </Flex>
    </Flex>
  )
}



