/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment, useContext } from 'react'
import { Router } from "@reach/router"
import { Feed } from "./feed"
import { Dashboard } from './dashboard'

import { Flex, Box, Container } from '@theme-ui/components'
import { Link as RouteLink } from 'gatsby'
import { Wallpapers } from './wallpapers'
import { UserContext, Users } from "../../plugins/gatsby-firebase-authentication";

import { Comments } from '../../plugins/gatsby-firebase-comments/src/admin/index'

export const IndexPage = () => {
  const user = useContext(UserContext)
  return (
    <Flex sx={{ backgroundColor: 'muted', height: '100%', flexDirection: 'column' }}>
      {user && (
        <Fragment>
          <Box sx={{
            backgroundColor: 'yellow.2',
          }}>
            <Container variant="narrow">
              <Flex py={2} mb={0} sx={{ overflowX: 'auto', overflowY: 'hidden' }} >
                <Box mr={3} sx={{ flex: '0 0 auto' }}>
                  <RouteLink sx={{ variant: 'links.menu' }} activeClassName="active" to="/app/">Home</RouteLink>
                </Box>
                <Box mr={3} sx={{ flex: '0 0 auto' }}>
                  <RouteLink sx={{ variant: 'links.menu' }} activeClassName="active" to="/app/feed">Feed</RouteLink>
                </Box>
                <Box mr={3} sx={{ flex: '0 0 auto' }}>
                  <RouteLink sx={{ variant: 'links.menu' }} activeClassName="active" to="/app/wallpapers">Wallpapers</RouteLink>
                </Box>
                <Box mr={3} sx={{ flex: '0 0 auto' }}>
                  <RouteLink sx={{ variant: 'links.menu' }} activeClassName="active" to="/app/comments">Comments</RouteLink>
                </Box>
                <Box mr={3} sx={{ flex: '0 0 auto' }}>
                  <RouteLink sx={{ variant: 'links.menu' }} activeClassName="active" to="/app/users">Users</RouteLink>
                </Box>
              </Flex>
            </Container>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Router sx={{ height: '100%' }}>
              <Dashboard path="/app/" />
              <Wallpapers path="/app/wallpapers" />
              <Feed path="/app/feed" />

              {/* FROM gatsby-firebase-comments */}
              <Comments path="/app/comments" />
              <Users path="/app/users" />
            </Router>
          </Box>
        </Fragment>
      )}

    </Flex>

  )
}