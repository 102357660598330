/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Flex } from '@theme-ui/components'

export const Cell: FC<any> = ({ children, halign = "center", valign = "center" }) => {
  return (
    <Flex sx={{ alignItems: valign, justifyContent: halign }}>
      {children}
    </Flex>
  )
}