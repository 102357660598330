/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC, Fragment, useContext } from "react"
import { Flex, Text } from '@theme-ui/components'
import { TableContext } from "./tableContext";
import { TableRow } from "./tableRow";
import { Cell } from "./cell";
import { ColumnCheckbox } from "./columnCheckbox";
import { ColumnMore } from "./columnMore";
import { ColumnText } from "./columnText";

export interface TableContentProps {
  columns: string[],
  selection: any,
  select: any,
  rowSelector: any
}

const ColumnTypes: any = {
  ColumnCheckbox,
  ColumnMore,
  ColumnText
}

export const TableContent: FC<TableContentProps> = (props: any) => {
  const {
    columns,
    selection,
    rowSelector
  } = props
  const { content } = useContext(TableContext)

  return <Fragment>
    {content.map((comment: any) => {
      return (
        <TableRow
          columns={columns}
          id={comment.uid}
          key={comment.uid}
          selected={!!selection[comment.uid]}
        >
          {rowSelector.map((r: any, i: number) => {
            const T = ColumnTypes[r.type]
            const cont = r.selector ? r.selector({ comment }) : null
            const opts = r.opts ? r.opts({ comment }, props) : { name: comment.uid }

            return <Cell key={i} {...r.containerOpts}>
              <T {...opts}>
                {cont}
              </T>
            </Cell>
          })}

        </TableRow>
      )
    })}
  </Fragment >
}