import { useState } from 'react'
import { Comment, FirebaseComment } from '../models/comments'
import { useFirebase } from 'gatsby-plugin-firebase'
import { app } from 'firebase';

export interface adminCommentOpts {
  limit: number,
  orderBy: string,
  orderDir: "desc" | "asc"
}

export function useAdminComments(opts: any) {
  const {
    limit = 20,
    orderBy = "createdAt",
    orderDir = "desc"
  } = opts
  const [comments, setComments] = useState<FirebaseComment[]>([])

  useFirebase((firebase: app.App) => {
    firebase
      .firestore()
      .collectionGroup("comments")
      .orderBy(orderBy, orderDir)
      .limit(limit)
      .get()
      .then((snapshot) => {
        const cs: FirebaseComment[] = []

        snapshot.docs.forEach((doc) =>
          cs.push({ ...doc.data() as Comment, uid: doc.id, fref: doc.ref }),
        );

        setComments(cs)
      })
  }, []);



  return { comments }
}