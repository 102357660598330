/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Box } from '@theme-ui/components'

export const TableBody: FC = ({ children }) => {
  return (
    <Box sx={{ flex: 1 }}>
      {children}
    </Box>
  )
}
