
/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Checkbox } from '@theme-ui/components'

export const ColumnCheckboxGlobal: FC<any> = ({ name, checked, onChange }) => {
  return (
    <label htmlFor={name}>
      <Checkbox id={name} name={name} checked={checked} onChange={onChange} />
    </label>
  )
}

