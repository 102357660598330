/** @jsx jsx */
import { jsx, } from "theme-ui";

import { useState, FC, useContext, Fragment } from "react"
import LazyLoad from 'react-lazyload';

import { Heading, Box, Link, Grid, Button, Flex, Container } from '@theme-ui/components'
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'
import { RouteComponentProps } from "@reach/router";

// import check from '../images/check.svg'
import { app, firestore } from "firebase/app";

import { List } from "../components/molecules/list";
import { Modal } from "../components/organisms/modal";
import { Image } from "../model/image";
import { Wallpaper } from "../model/wallpaper";

import check from '../images/check.svg'
import { CardImage, CardTitle, Card } from "../components/organisms/card";
import { WallpaperEditor } from "./wallpaperEditor";


const createWallpaper = async (item: any, firebase: app.App) => {
  const data = item.data()
  const wallpaper: Wallpaper = {
    name: data.title,
    originalURL: data.refs[0].url,
    redditId: data.redditId,
    images: {},
    createdAt: firestore.Timestamp.fromMillis(Date.now())
  }
  const doc = await firebase
    .firestore()
    .collection("wallpapers")
    .add(wallpaper)

  await item.ref.set({ saved: true }, { merge: true })
  return doc
}

export const Feed: FC<RouteComponentProps> = () => {
  const [items, setItems] = useState<any[]>([])
  const [item, setItem] = useState<any | undefined>(undefined)
  const [wll, setWall] = useState<firebase.firestore.DocumentReference | undefined>(undefined)

  const firebase = useContext(FirebaseContext) as app.App

  console.log(wll)

  useFirebase((firebase: app.App) => {
    const now = new Date(Date.now())
    const ts = Math.floor(new Date(now.setMonth(now.getMonth() - 1)).getTime() / 1000);

    firebase
      .firestore()
      .collection('images')
      .where("created", ">", ts)
      .orderBy("created", "desc")
      .limit(100)
      .get()
      .then((results) => {
        const is = results
          .docs
          .filter(i => {
            const data = i.data()
            const ref = data.refs[0]
            const ratio = ref.width / ref.height
            if (ratio > 1) {
              return false
            }

            return true
          });

        setItems(is as any)
      })

  }, [])

  return (
    <Fragment>
      <Container>
        <Grid columns={[2, 3, 4]} my={4}>
          {items.map(i => {

            const data = i.data()
            const ref = data.refs[0]
            const ratio = ref.width / ref.height

            return <LazyLoad key={i.id} height={208 * (1 / ratio)}>

              <Box sx={{ my: 2, position: 'relative' }} >
                <Card onClick={() => {
                  setItem(i)
                }}>
                  <CardImage url={data.url} />
                  <CardTitle>{data.title}</CardTitle>
                </Card>
                {data.saved ? <Box sx={{
                  position: 'absolute',
                  top: '-8px',
                  right: '-8px'
                }}>
                  <img src={check} sx={{
                    width: '24px',
                    height: '24px'
                  }} />
                </Box> : null}
              </Box>
            </LazyLoad>
          })}
        </Grid >
        <Modal
          open={item !== undefined}
          close={() => setItem(undefined)}
        >
          {item && (
            <Grid sx={{
              gridTemplateColumns: ['1fr', '200px 380px']
            }}>
              <Box>
                <img width="200" style={{ height: 'auto', display: 'block' }} src={item.data().url} />
              </Box>

              <Flex sx={{ flexDirection: 'column' }}>
                <Box sx={{ flex: 1 }}>
                  <Heading as="h3">{item.data().title}</Heading>
                </Box>
                <Box>
                  <Button onClick={() => createWallpaper(item, firebase).then((wall) => { console.log(wall); setItem(undefined); setWall(wall) })}>Create</Button>
                </Box>
              </Flex>
            </Grid>
          )}
        </Modal>
        <WallpaperEditor open={!!wll} close={() => setWall(undefined)} wallpaper={wll} wid={wll?.id} />
      </Container >
    </Fragment >
  )
}

export default Feed
