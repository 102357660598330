/** @jsx jsx */
import { jsx, } from "theme-ui";

import { FC, useState, useEffect, useContext, Fragment } from "react";
import { RouteComponentProps } from "@reach/router";
import { Box, Heading, Text, Container, Grid, Checkbox, Flex, Button, IconButton, Badge } from '@theme-ui/components'
import { useFirebase, FirebaseContext } from 'gatsby-plugin-firebase'

import { useAdminComments } from "../hooks/useAdminComments";
import { Table } from "../../../gatsby-plugin-components/src/table/table";
import { TableFooter } from "../../../gatsby-plugin-components/src/table/tableFooter";
import { TableHeader } from "../../../gatsby-plugin-components/src/table/tableHeader";
import { TableBody } from "../../../gatsby-plugin-components/src/table/tableBody";
import { ColumnCheckbox } from "../../../gatsby-plugin-components/src/table/columnCheckbox";
import { CellHeader } from "../../../gatsby-plugin-components/src/table/cellHeader";
import { useSelection } from "../../../gatsby-plugin-components/src/table/useSelection";
import { TableContent } from "../../../gatsby-plugin-components/src/table/tableContent";
import { TableContentHeader } from "../../../gatsby-plugin-components/src/table/tableContentHeader";


export const Comments: FC<RouteComponentProps> = () => {
  const [limit, setLimit] = useState(20)

  const { comments } = useAdminComments({
    limit,
  })

  const {
    selection,
    select,
    all,
    onEnableAll
  } = useSelection(comments)

  const rowSelector = [{
    type: 'ColumnCheckbox',
    headerType: 'ColumnCheckboxGlobal',
    opts: ({ comment }: any, props: any) => {
      name: comment?.uid
    }
  }, {
    name: 'Comment',
    type: 'ColumnText',
    selector: ({ comment }: any) => {
      return comment.comment
    },
    containerOpts: {
      valign: 'flex-start'
    }
  }, {
    name: 'Created at',
    type: 'ColumnText',
    selector: ({ comment }: any) => {
      return comment.createdAt?.toDate().toLocaleString()
    }
  }, {
    name: 'Actions',
    type: "ColumnMore",
    headerType: "ColumnText",
    containerOpts: {
      halign: 'flex-end'
    }
  }]

  return (
    <Container variant="narrow" sx={{ height: '100%' }}>
      <Table content={comments} select={select} selection={selection} selectAll={onEnableAll}>
        <TableHeader selection={selection} />
        <TableBody>
          <TableContentHeader
            select={select}
            columns={["auto 1fr 140px 60px"]}
            selection={selection}
            rowSelector={rowSelector}
          />
          <TableContent
            select={select}
            columns={["auto 1fr 140px 60px"]}
            selection={selection}
            rowSelector={rowSelector} />
        </TableBody>
        <TableFooter />
      </Table>
    </Container >
  )
}