/** @jsx jsx */
import { jsx } from "theme-ui";

import { IndexPage } from "../app";

const App = () => {
  return (
    <IndexPage />
  )
}

export default App
