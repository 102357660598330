/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC, Fragment, useContext } from "react"
import { Flex, Text, Grid } from '@theme-ui/components'
import { TableContext } from "./tableContext";
import { TableRow } from "./tableRow";
import { Cell } from "./cell";
import { ColumnCheckbox } from "./columnCheckbox";
import { ColumnMore } from "./columnMore";
import { ColumnText } from "./columnText";
import { CellHeader } from "./cellHeader";
import { ColumnCheckboxGlobal } from './columnCheckboxGlobal'

export interface TableContentProps {
  row?: boolean
  header?: boolean
  columns: string[],
  selection: any,
  select: any,
  rowSelector: any
}

const ColumnTypes: any = {
  ColumnCheckbox,
  ColumnCheckboxGlobal,
  ColumnMore,
  ColumnText
}

export const TableContentHeader: FC<TableContentProps> = (props: any) => {
  const {
    row,
    columns,
    selection,
    select,
    rowSelector
  } = props
  const { content } = useContext(TableContext)

  return (
    <Grid p={3} sx={{
      gridTemplateColumns: columns,
      borderBottom: '1px solid',
      borderColor: 'muted',
    }} >
      {rowSelector.map((r: any, i: number) => {
        const T = r.headerType ? ColumnTypes[r.headerType] : ColumnTypes[r.type]

        return (
          <CellHeader key={i} {...r.containerOpts}>
            <T {...(r.opts ? r.opts({}, { ...props, header: true }) : {})}>
              {r.name}
            </T>
          </CellHeader>
        )
      })}
    </Grid>
  )
}