/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { IconButton } from '@theme-ui/components'
import { IconVertMore } from "../icons/vertMore";

export const ColumnMore: FC<any> = ({ name, checked, onChange }) => {
  return (
    <IconButton sx={{
      my: -2, color: 'purple.0', ":hover": {
        color: 'purple.1',
        cursor: 'pointer'
      }
    }}>
      <IconVertMore />
    </IconButton>
  )
}

