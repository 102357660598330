
/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC, useContext } from "react"
import { Checkbox } from '@theme-ui/components'
import { TableContext } from "./tableContext";

export const ColumnCheckbox: FC<any> = (props) => {
  const { name } = props
  const { select, selection } = useContext(TableContext)
  console.log(props)
  const checked = !!selection[name]

  return (
    <label htmlFor={name}>
      <Checkbox id={name} name={name} checked={checked} onChange={() => select(name)} />
    </label>
  )
}

