
/** @jsx jsx */
import { jsx, } from "theme-ui";
import { FC } from "react"
import { Text } from '@theme-ui/components'

export const ColumnText: FC<any> = ({ children }) => {
  return (
    <Text>
      {children}
    </Text>
  )
}

